// Password format checker
export function checkPasswordLength(pass: string): boolean {
  if (pass == null) return false;

  return pass.length >= 8;
}

export function checkPasswordContainsAlphabet(pass: string): boolean {
  if (pass == null) return false;

  return /[a-zA-Z]/.test(pass);
}

export function checkPasswordContainsNumber(pass: string): boolean {
  if (pass == null) return false;

  return /\d/.test(pass);
}
